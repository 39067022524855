import styled from '@emotion/styled'

export const MainContainer = styled.div`
  opacity: 0;
  transition: opacity .4s ease-out;
  &.loaded {
    opacity: 1;
  }
`;
export const AdsHomeContainer = styled.div`
  padding-top: 75px;
  padding-bottm: 65px;
  a {
    color: #0080ff;
    text-decoration: none;
    transition: color .1s ease-out;
    font-weight: 600;
    &:hover {
      color: #333;
    }
  }
  .headline {
    position: relative;
    h3 {
      font-size: 26px;
      line-height: 36px;
      font-weight: 500;
      color: #333;
      text-align: left;
      padding: 0;
    }
    a {
      text-decoration: underline;
      text-transform: uppercase;
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 12px;
    }
  }
  ${'' /* @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  } */}
`

export const AdGridLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-top: 30px;
  @media (max-width: 768px) {
    width: 100%;
  }
  &.full {
    margin-top: 0;
    width: 100%;
  }
`;

export const AdGridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 30px 30px 0;
  width: calc(100% * (1 / 2) - 30px);
  border-radius: 4px;
  border: 1px solid #eee;
  background-color: #fff;
  transition: .3s;
  position: relative;
  &:hover {
    transform: translateY(-1px);
    box-shadow:0 2px 10px 0 rgba(0,0,0,.12);
  }
  @media (max-width: 768px) {
    margin: 0 0 15px;
    width: 100%;
  }
  a {
    color: #0080ff;
    text-decoration: none;
    transition: color .1s ease-out;
    font-weight: 600;
    &:hover {
      color: #333;
    }
  }
  .details {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px 0 0;
    align-items: center;
    height: 150px;
    @media (max-width: 768px) {
      padding: 0px!important;
    }
    .hero {
      max-width: 150px;
      margin-right: 24px;
      top: 0;
      flex: 0 0 auto;
      width: auto;
      height: 100%;
      @media (max-width: 768px) {
        display: block;
        padding-bottom: 20px;
        flex: 0 0 100%;
        height: 200px;
        width: 100%;
        object-fit: cover;
        max-width: 100%;
      }
      img {
        max-width: 100%;
        border-radius: 4px;
        border: 1px solid #eee;
        transform: translate3d(0,0,0);
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (max-width: 768px) {
          height: 200px;
        }
      }
    }
    .description {
      flex: 1;
      padding: 10px 0;
      @media (max-width: 768px) {
        padding: 15px;
      }
      h3 {
        font-size: 20px;
        line-height: 28px;
        word-break: break-word;
        text-align: left;
        margin: 0 0 10px 0;
        font-weight: 500;
        padding: 0;
      }
      h5 {
        font-size: 14px;
        color: #555;
        font-weight: 500;
        margin: 0;
        padding: 0;
        a {
          color: #555;
          transition: color .1s ease-out;
          &:hover {
            color: black;
          }
          svg {
            fill: #555;
            height: 12px;
          }
        }
      }
      p {
        color: #777;
      }
      .breadcrumb {
        margin-bottom: 5px;
        list-style: none;
        border-radius: 4px;
        background-color: transparent;
        padding-left: 0;
        color: #909090!important;
        font-size: 14px;
        margin-top: 0;
        li {
          display: inline-block;
          a {
            svg {
              fill: #0080FF;
              height: 12px;
            }
          }
        }
        &>li+li:before {
          content: "/";
          color: #0080FF;
          padding: 0 5px;
        }
      }
    }
  }

  .footer {
    flex-grow: 0;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    background-color: #fafafa;
    border-radius: 0 0 4px 4px;
    position: relative;
    @media (max-width: 768px) {
      padding-bottom: 14px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      &:last-of-type {
        li {
          button {
            padding: 0;
            font-size: 16px;
            border: none;
            background: transparent;
            cursor: pointer;
            transition: color .1s ease-out;
            color: #555;
            display: flex;
            align-items: center;
            font-family: ${props => props.theme.fontFamily};
            svg {
              fill: transparent;
              stroke: #777;
              margin-top: -1px;
              width: 17px;
              margin-right: 6px;
              @media (max-width: 768px) {
                width: 15px;
              }
            }
            &:hover {
              color: black;
              svg {
                stroke: #333;
              }
            }
          }
        }
      }
      li {
        display: inline-block;
        margin-right: 16px;
        color: #777;
        font-size: 16px;
        font-weight: 600;
        @media (max-width: 768px) {
          font-size: 14px;
        }
        a, span {
          display: flex;
          align-items: center;
          svg {
            margin-top: -1px;
            fill: #777;
            width: 17px;
            margin-right: 6px;
            @media (max-width: 768px) {
              width: 15px;
            }
          }
        }
        a {
          color: #555;
          transition: color .1s ease-out;
          &:hover {
            color: black;
          }
          svg {
            fill: #333;
          }
        }
      }
    }
  }

  &.large {
    width: 80%;
    margin: 0 auto 30px;
    .details {
      height: 200px;
      .hero {
        max-width: 200px;
      }
    }
  }
  &.full {
    width: 100%;
    margin: 0 auto 30px;
    .details {
      height: 200px;
      .hero {
        max-width: 200px;
      }
    }
  }
`;

export const AdContainer = styled.div`
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      &:before {
        content: " ";
        display: flex;
      }
      .col-md-12, .col-xl-8, .col-xl-4, .col-md-6 {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
      }
      .col-xl-8 {
        ${'' /* background: #ffff34; */}
        @media (min-width: 1200px) {
          flex: 0 0 66.666667%;
          max-width: 66.666667%;
        }
      }
      .col-xl-4 {
        ${'' /* background: #ff2134; */}
        @media (min-width: 1200px) {
          flex: 0 0 33.333333%;
          max-width: 33.333333%;
        }
      }
      .col-md-6 {
        ${'' /* background: #ff2134; */}
        @media (min-width: 1200px) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }

    h3 {
      text-align: left;
      padding: 0;
      margin-bottom: 25px;
      margin-top: 0px;
      font-size: 20px;
    }
  }

  .headline {
    padding-bottom: 40px;
    .property {
      position: relative;
      font-size: 16px;
      margin-bottom: 25px;
      display: flex;
      > div {
        padding-left: 10px;
        span {
          font-weight: 600;
          color: #333;
          margin: 0;
          padding: 0;
          display: block;
        }
        h5 {
          display: block;
          font-weight: 500;
          color: #666;
          font-size: 16px;
          margin: 0;
          padding: 0;
          line-height: 20px;
        }
      }
      svg {
        height: 25px;
        fill: #0080FF;
      }
    }
  }

  .content {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
  }

  .sidebar-container {
    margin-bottom: 40px;
    .sidebar-widget {
      margin-bottom: 30px;
      display: block;
      .detail {
        border-radius: 4px;
        border: 1px solid #eee;
        .header {
          text-align: center;
          font-weight: 600;
          font-size: 20px;
          padding: 14px 30px;
          background-color: #f9f9f9;
          color: #333;
          position: relative;
          border-radius: 4px 4px 0 0;
          border-bottom: 1px solid #eee;
        }
        .body {
          padding: 30px;
          text-align: center;
          .avatar {
            border: 1px solid #eee;
            max-width: 150px;
            border-radius: 4px;
            overflow: hidden;
            margin: 0 auto;
            margin-bottom: 15px;
            a {
              display: block;
              height: 100%;
              img {
                vertical-align: middle;
                border: none;
                max-width: 100%;
              }
            }
          }
          h2 {
            font-size: 22px;
            margin-bottom: 20px;
            font-weight: 600;
            a {
              color: #333;
              transition: color .1s ease-out;
              text-decoration: none;
              &:hover {
                color: #0080FF;
              }
            }
          }
          ul {
            padding: 0;
            margin: 0 0 30px 0;
            list-style: none;
            li {
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              margin-bottom: 10px;
              svg {
                margin-right: 5px;
                height: 24px;
                fill: #0080FF;
              }
            }
          }
        }
      }
    }
  }

  .button {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0080FF;
    box-shadow: 0 4px 12px rgba(0,128,255,0.15);
    color: #fff;
    top: 0;
    padding: 10px 20px;
    line-height: 28px;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    transition: all .2s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    &:hover {
      filter:grayscale(.2);
    }
    svg {
      margin-left: 5px;
      height: 18px;
      fill: #fff;
    }
  }
`;

export const LatestAds = styled.div`
  > div {
    display: block;
    border-radius: 4px;
    border: 1px solid #eee;
    margin-bottom: 30px;
    background-color: #fff;
    transition: .3s;
    position: relative;
    &:hover {
      transform: translateY(-1px);
      box-shadow:0 2px 10px 0 rgba(0,0,0,.12);
    }
    > .details {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding:0;
      .image-container {
        max-width: 100%;
        margin-right: 24px;
        top: 0;
        flex: 0 0 150px;
        width: 150px;
        height: 150px;
        img {
          border-radius: 4px;
          border: 1px solid #eee;
          transform: translate3d(0,0,0);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .description {
        flex: 1;
        padding: 10px 0;
        p {
          margin: 0;
          padding: 0;
          color: #666;
          word-break: break-word
        }
        .description-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          h3 {
            font-size: 20px;
            line-height: 30px;
            word-break: break-word;
            margin: 0;
            text-align: left;
            padding: 0;
          }

          .category {
            font-size: 14px;
            cursor: pointer;
            background-color: #fff;
            color: #0080ff;
            border: 1px solid #0080ff; 
            display: block;
            border-radius: 3px;
            transition: all .4s;
            font-weight: 500;
            white-space: nowrap;
            text-align: center;
            margin-right: 30px;
            a {
              height: 100%;
              display: flex;
              width: 100%;
              padding: 6px 10px;
              align-items: center;
              justify-content: center;
            }
            svg {
              display: block;
              margin-right: 2px;
              width: 18px;
              fill: #0080ff;
            }
          }
        }
      }
      .footer {
        border-radius: 0 0 4px 4px;
        position: relative;
        ul.prop_details {
          list-style: none;
          padding: 0;
          margin: 0;
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          &:first-of-type {
            display: block;
            max-width: 80%;
          }
          li {
            font-size: 16px;
            display: inline;
            margin-right: 14px;
            color: #777;
            &.list-inline-item {
              display: inline-block;
              margin-right: 10px;
            }
            &:last-of-type {
              margin-right: 0
            }
            a {
              display: inline;
              color: #777;
              text-decoration: none;
              transition: color .1s ease-out;
              &:hover {
                color: #333;
              }
            }
            svg {
              position: relative;
              top: 3px;
              margin-right: 3px;
              font-size: 18px;
              fill: #777;
              display: inline-block;
              max-width: 18px;
            }
          }
        }
      }
    }
  }
`;

export const AdTitleBar = styled.div`
  background-color: #f8f8f8;
  position: relative;
  padding: 40px 0;

  h1, h2 {
    font-size: 30px;
    line-height: 40px;
    margin: 1px 0 3px;
  }

  nav#breadcrumb {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    color: #555;
    ul {
      margin: 0;
      padding: 0;
      line-height: 23px;
      li {
        display: inline-block;
        list-style: none;
        margin: 0 0 0 7px;
        &:not(:first-of-type):before {
          content: "";
          display: inline-block;
          height: 0;
          width: 0;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          border-left: 4px solid #555;
          font-size: 14px;
          margin-right: 12px;
        }
        a {
          text-decoration: none;
          transition: all .2s ease-in-out;
          color: #000;
          font-weight: 500;
          &:hover {
            color: #0080ff;
          }
        }
      }
    }
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
`;

export const Slide = styled.div`
  width: 100%;
  height: 400px;
  background: #eee;
  display: flex!important;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  
  img {
    max-width: 1170px;
    visibility: hidden;
    display: inline-block;
  }
`;

export const UploadMainButtons = styled.button`
  width: auto;
  text-align: center;
  display: inline-block;
  top: 0;
  padding: 5px 15px;
  line-height: 24px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  transition: all .2s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  border: none;
  border-radius: 4px;
  margin-top: 10px;
  margin-right: 15px;
  margin-bottom: 15px;
  z-index: 1;
  font-family: ${props => props.theme.fontFamily};
  background: ${props => props.theme.colors.buttonsBackground};
  &:hover {
    background: ${props => props.theme.colors.buttonsBackgroundHover};
  }
`;
export const ImgThumb = styled.div`
  margin-bottom: 15px;
  .img-container {
    border: 1px solid #eee;
    width: 100%;
    height: 180px;
    padding: 3px;
    margin-right: 15px;
    overflow: hidden;
    display: flex;
    position: relative;
    img {
      object-fit: cover;
      width: 100%;
      vertical-align: middle;
      border-style: none;
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  button {
    width: auto;
    text-align: center;
    display: block;
    top: 0;
    padding: 2px 10px;
    line-height: 24px;
    color: #fff;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    transition: all .2s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
    z-index: 1;
    font-family: ${props => props.theme.fontFamily};
    background: ${props => props.theme.colors.buttonsBackground};
    &:hover {
      background: ${props => props.theme.colors.buttonsBackgroundHover};
    }
  }
`;