import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn, API_isLoggedIn } from "../services/auth"

class PrivateRoute extends React.Component {

  async componentDidMount() {
    const API_LOGGED = await API_isLoggedIn();
    if (!API_LOGGED) {
      navigate("/app/connexion", { replace: true });
    }
  }

  render() {
    const { component: Component, location, ...rest } = this.props;
    
    if (!isLoggedIn() && location.pathname !== `/app/connexion`) {
      navigate("/app/connexion", { replace: true })
      return null
    }
    return <Component {...rest} />
  }
}

export default PrivateRoute