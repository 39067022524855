const axios = require('axios');
const isBrowser = typeof window !== `undefined`

// Retrieve user
const getUser = () =>
  window.localStorage.gatsbyUser
    ? JSON.parse(window.localStorage.gatsbyUser)
    : {}

// Set user
const setUser = user => (window.localStorage.gatsbyUser = JSON.stringify(user))

// Handle login form
export const handleLogin = async ({ username, password }) => {
  if (!isBrowser) return false

  const r = await axios.post(`${process.env.AFC_API_ENDPOINT}`, {
    action: 'signin',
    username: username,
    password: password,
  }, {
    headers: {
      "Content-Type": "application/json",
    }
  });

  if (Object.keys(r.data).length === 0) {
    return { error: `API unknown error` };
  } else if (r.data.error) {
    return { error: r.data.error };
  } else {
    const { id, username, name, email, token } = r.data;

    return setUser({
      id: id,
      name: username,
      legalName: name,
      email: email,
      token: token,
    });
  }
}

// Handle signup form
export const handleSignup = async ({ name, email, password }) => {
  if (!isBrowser) return false

  const r = await axios.post(`${process.env.AFC_API_ENDPOINT}`, {
    action: 'signup',
    name: name,
    email: email,
    password: password,
  }, {
    headers: {
      "Content-Type": "application/json",
    }
  });

  if (Object.keys(r.data).length === 0) {
    return { error: `API unknown error` };
  } else if (r.data.error) {
    return { error: r.data.error };
  } else {
    const { id, username, name, email, token } = r.data;

    return setUser({
      id: id,
      name: username,
      legalName: name,
      email: email,
      token: token,
    });
  }
}

// Check email exists
export const checkEmail = async ({ email }) => {
  if (!isBrowser) return false

  const r = await axios.post(`${process.env.AFC_API_ENDPOINT}`, {
    action: 'password_request',
    email: email,
  }, {
    headers: {
      "Content-Type": "application/json",
    }
  });
  
  if (Object.keys(r.data).length === 0) {
    return { error: `API unknown error` };
  } else if (r.data.error) {
    return { error: r.data.error };
  } else {
    const { name, id, email, token } = r.data;
    return {
      name: name,
      id: id,
      email: email,
      token: token
    }
  }
}

// Submit new password
export const submitPassword = async ({ id, password, token }) => {
  if (!isBrowser) return false

  const r = await axios.post(`${process.env.AFC_API_ENDPOINT}`, {
    action: 'password_update',
    id: id,
    password: password,
    token: token
  }, {
    headers: {
      "Content-Type": "application/json",
    }
  });

  if (Object.keys(r.data).length === 0) {
    return { error: `API unknown error` };
  } else if (r.data.error) {
    return { error: r.data.error };
  } else {
    return { success: true }
  }
}

// Check if user is logged
export const isLoggedIn = () => {
  if (!isBrowser) return false
  const user = getUser();
  return !!user.token;
}

// Check if user is logged to API
export const API_isLoggedIn = async () => {
  const user = getUser();
  const r = await axios.post(`${process.env.AFC_API_ENDPOINT}`, {
    action: 'isloggedin',
    id: user.id,
    token: user.token,
  }, {
    headers: {
      "Content-Type": "application/json",
    }
  });

  if (Object.keys(r.data).length === 0) {
    console.log('API unknown error');
  } else if (r.data.error) {
    console.log(r.data.error);
  } else {
    const { isloggedin } = r.data;
    return isloggedin;
  }
}

// Get current uqser
export const getCurrentUser = () => isBrowser && getUser()

// Kill session
export const logout = callback => {
  if (!isBrowser) return
  setUser({})
  callback()
}