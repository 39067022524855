import React from "react"
import { Link } from "gatsby"
import { Baseline, View } from '../../containers/MainWrapper'

const pagesMapping = {
  ads: {
    slug: 'annonces',
    label: 'Mes annonces'
  },
  settings: {
    slug: 'configuration',
    label: 'Paramètres du compte',
  },
  editad: {
    slug: 'collection',
    label: 'Mettre à jour une annonce'
  }
}

export default ({ page }) => {
  
  return (
    <Baseline>
      <View>
        <h2>Mon compte</h2>
        <nav id="breadcrumb">
          <ul>
            <li><Link to="/annonces">Accueil</Link></li>
            {
              page === 'editad' ? (
                <React.Fragment>
                  <li>
                    <Link to={`/app/mon-compte`}>Mon compte</Link>
                  </li>
                  <li>
                    <Link to={`/app/mon-compte`}>{pagesMapping[page].label}</Link>
                  </li>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <li>
                    <Link to={`/app/mon-compte`}>Mon compte</Link>
                  </li>
                  {
                    page !== 'dashboard' && (
                      <React.Fragment>
                        {
                          page === 'new' ? (
                            <li>
                              <Link to={`/app/mon-compte/creer`}>Créer une annonce</Link>
                            </li>
                          ) : (
                            <li>
                              <Link to={`/app/mon-compte/${pagesMapping[page].slug}`}>{pagesMapping[page].label}</Link>
                            </li>
                          )
                        }
                      </React.Fragment>
                    )
                  }
                </React.Fragment>
              )
            }
          </ul>
        </nav>
      </View>
    </Baseline>
  )
}