import styled from '@emotion/styled'

export const DashboardSidebar = styled.div`
  flex: 0 0 280px;
  background-color: #fff;
  position: relative;
  border: 1px solid #e4e4e4;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 5%);
  z-index: 100;
  nav {
    display: block;
    z-index: 11;
    padding-bottom: 20px;
    ul {
      list-style: none;
      padding: 0;
      margin: 20px 0 0;
      li {
        display: flex;
        align-items: center;
        border-left: 3px solid transparent;
        transition: all .3s ease-out;
        line-height: 25px;
        font-size: 15px;
        color: #707070;
        svg {
          display: block;
          margin-left: 30px;
          stroke: #707070;
          width: 20px;
        }
        &:hover, &.active {
          background-color: rgba(0,128,255,0.04);
          border-color: ${props => props.theme.colors.buttonsBackground};
          svg {
            stroke: ${props => props.theme.colors.buttonsBackground};
          }
        }
        a {
          color: #707070;
          display: block;
          padding: 12px 30px 8px 10px;
          transition: .3s;
          font-size: 14px;
          cursor: pointer;
          position: relative;
          &:hover {
            color: ${props => props.theme.colors.buttonsBackground};
          }
          .nav-tag {
            background: ${props => props.theme.colors.buttonsBackground};
            display: inline-block;
            font-weight: 700;
            height: 19px;
            min-width: 19px;
            padding: 0 5px;
            line-height: 19px;
            text-align: center;
            color: #fff;
            font-size: 11px;
            border-radius: 10px;
            position: relative;
            margin: 0 0 0 4px;
            top: -2px;
          }
        }
      }
    }
  }
`;

export const DashboardViewport = styled.div`
  display: block;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 5%);
  position: relative;
  margin-bottom: 50px;
  .content {
    padding: 30px;
    .profile {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      .user-img {
        float: left;
        border: 1px solid #eee;
        width: 120px;
        height: 120px;
        padding: 3px;
        margin-right: 15px;
        overflow: hidden;
        display: flex;
        img {
          object-fit: cover;
          width: 100%;
          vertical-align: middle;
          border-style: none;
        }
      }
      h2 {
        font-size: 26px;
        margin: 0;
        padding: 0;
      }
      button {
        display: block;
        border: none;
        background: transparent;
        padding: 5px 0 0 0;
        color: ${props => props.theme.colors.buttonsBackground};
        cursor: pointer;
      }
    }
    .badges {
      display: inline-block;
      padding: 0 20px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      &:last-of-type {
        border: none;
      }
      strong {
        font-weight: 600;
        font-size: 20px;
        display: block;
      }
    }
  }

  .headline {
    display: block;
    padding: 20px 30px;
    border-bottom: 1px solid #e4e4e4;
    position: relative;
    h3 {
      font-size: 16px;
      font-weight: 600;
      color: #333;
      line-height: 26px;
    }
  }
  .fields {
    margin-bottom: 28px;
    display: block;
    h5 {
      font-size: 16px;
      font-weight: 600;
      color: #333;
      margin-bottom: 12px;
    }
    .inputs {
      input[type=text], select {
        border: 1px solid #e0e0e0;
        box-shadow: 0 1px 4px 0 rgb(0 0 0 / 5%);
        height: 48px;
        line-height: 48px;
        padding: 0 20px;
        outline: 0;
        font-size: 16px;
        color: gray;
        margin: 0 0 16px;
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        display: block;
        background-color: #fff;
        font-weight: 500;
        opacity: 1;
        border-radius: 4px;
      }
    }
  }
`;